import "@/styles/globals.css";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "@/styles/theme";
import "@/fontello/css/cbt.css";
import { BaseCSS } from "styled-bootstrap-grid";
import NextNProgress from "nextjs-progressbar";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import App from "next/app";

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 1em;
    line-height: 1.25;
  }
  
  p {
    line-height: 1.5;
  }
  
  a {
    color: ${theme.accent_3}
  }
`;

function MyApp({ Component, pageProps }) {
  return (
    <>
      {process.env.NODE_ENV === `production` && (
        <Script id={`disable-dev-tools`}>
          {`
          function isFunction(obj) {
            return typeof obj === "function";
          }
        if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
          for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
            if (prop === "renderers") {
              // prevents console error when dev tools try to iterate of renderers
              window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
              continue;
            }
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
              window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
            )
              ? Function.prototype
              : null;
          }
        }
        `}
        </Script>
      )}
      <GlobalStyle />
      <BaseCSS />
      <ThemeProvider theme={theme}>
        <NextNProgress
          color={theme.accent_3}
          startPosition={0.3}
          height={2}
          showOnShallow={true}
        />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}

// 404 redirect here
MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  if (appContext.ctx.res?.statusCode === 404) {
    appContext.ctx.res.writeHead(302, { Location: "/" });
    appContext.ctx.res.end();
  }
  return { ...appProps };
};

export default appWithTranslation(MyApp);
