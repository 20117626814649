const theme = {
  pageWidth: `1200px`,
  primary: "#003B45",
  secondary: "#007C92",
  accent_1: "#451F00",
  accent_2: "#914200",
  accent_3: "#DE7116",

  //  greys
  grey_1: "#212121",
  grey_2: "#424242",
  grey_2_5: "#929292",
  grey_3: "#e0e0e0",
  grey_4: "#eeeeee",
  grey_5: "#fafafa",
  grey_6: "#fffffa",

  white: "#ffffff",

  // colours for prices
  up: "#69F0AE",
  down: "#FF5252",

  hexToRgb: function (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    return result ? `${r}, ${g}, ${b}` : null;
  },
  upDown(value) {
    return value > 0 ? this.up : this.down;
  },
};

export default theme;
